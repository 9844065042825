
import Vue from "@/extensions/Vue";
import {Options, prop} from "vue-class-component";
import JobData from "@/http/data/job-data";
import anime from "animejs";
import {jobContext} from "@/store";
import TextAreaDialog from "@/components/ui/TextAreaDialog.vue";

class Props {
    job = prop({
        required: true,
        type: Object
    });
    mode = prop({
        required: false,
        type: String,
        default: "type"
    });
}

@Options({
    name: "JobButton",
    components: {
        TextAreaDialog: TextAreaDialog
    }
})
export default class JobButton extends Vue.with(Props) {
    declare job: JobData;
    declare mode: string;

    cancelJob = jobContext.actions.cancelJob;
    performJob = jobContext.actions.performJob;

    showControls = false;
    requestInProgress = false;

    textAreaDialog!: TextAreaDialog;

    mounted(): void {
        this.$events.on("hide-job-controls", (uuid: number|undefined) => {
            if (uuid && uuid === this.uuid) return;

            this.hideControls();
        });

        this.textAreaDialog = this.$refs["textAreaDialog"] as TextAreaDialog;
    }

    get isCancelled(): boolean {
        return !!this.job.cancelled_at;
    }

    get isPerformed(): boolean {
        return !this.isCancelled && !!this.job.performed_at;
    }

    get status(): string {
        if (this.isCancelled) return "is-cancelled";
        if (this.isPerformed) return "is-performed";
        else return "is-unfinished";
    }

    displayControls(): void {
        if (this.showControls) return;

        this.showControls = true;

        anime({
            targets: `#controls-${this.uuid}`,
            translateX: ["100%", 0],
            duration: 500,
            easing: "easeInOutSine"
        });
    }
    
    hideControls(): void {
        if (!this.showControls) return;

        this.showControls = false;

        anime({
            targets: `#controls-${this.uuid}`,
            translateX: [0, "101%"],
            duration: 500,
            easing: "easeInOutSine"
        });
    }

    async onCancelJob(reason: string): Promise<void> {
        this.requestInProgress = true;

        await this.cancelJob({
            uuid: this.job.uuid,
            reason
        });

        this.requestInProgress = false;

        this.hideControls();
    }

    onClick(): void {
        if (this.isPerformed || this.isCancelled) return;

        if (!this.showControls) {
            this.$events.emit("hide-job-controls", this.uuid);
            
            this.displayControls();
        } else {
            this.hideControls();
        }
    }

    async onPerformJob(): Promise<void> {
        this.requestInProgress = true;

        await this.performJob(this.job.uuid);

        this.requestInProgress = false;

        this.hideControls();
    }

    openCancelDialog(): void {
        this.textAreaDialog.open();
    }
}
