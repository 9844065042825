<template>
    <div class="modal" :class="{'d-block': isVisible}" tabindex="-1" @click.stop="close()">
        <div class="modal-dialog modal-dialog-centered" @click.stop="">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ title }}</h5>
                    <button type="button"
                            class="btn-close"
                            aria-label="Close"
                            @click.stop="close()">
                    </button>
                </div>
                <div class="modal-body">
                    <el-form ref="elForm" :model="formData" :rules="formRules">
                        <el-form-item prop="input">
                            <el-input type="textarea"
                                      class="w-100"
                                      :rows="3"
                                      :minlength="min"
                                      :maxlength="max"
                                      v-model="formData.input"
                                      :show-word-limit="max > 0" />
                        </el-form-item>
                    </el-form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="close()">
                        {{ buttonCloseText }}
                    </button>
                    <button type="button" class="btn btn-primary" @click="action()">
                        {{ buttonActionText }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {Options, prop} from "vue-class-component";
import Vue from "../../extensions/Vue";
import {ElForm} from "element-plus";

class Props {
    buttonActionText = prop({
        required: false,
        type: String,
        default: "Ok"
    });
    buttonCloseText = prop({
        required: false,
        type: String,
        default: "Close"
    });
    title = prop({
        required: true,
        type: String
    });
    min = prop({
        required: false,
        type: Number,
        default: 0
    });
    max = prop({
        required: false,
        type: Number,
        default: -1
    });
}

@Options({
    name: "TextAreaDialog"
})
export default class TextAreaDialog extends Vue.with(Props) {
    declare buttonActionText: string;
    declare buttonCloseText: string;
    declare title: string;
    declare min: number;
    declare max: number;

    isVisible = false;

    formData = {
        input: ""
    }

    get inputCount(): number {
        return this.formData.input.length;
    }

    get formRules(): Record<string, unknown> {
        const input = [];

        if (this.min > 0) {
            input.push(
                {type: "string", required: true, message: "Field cannot be empty."},
                {min: this.min, message: `Input must contain at least ${this.min} characters.`, trigger: "blur"},
            );
        } else {
            input.push(
                {type: "string", required: false, message: "Input must be of type [string]."}
            );
        }

        if (this.max > 0) {
            input.push({max: this.max, message: `Input cannot contain more than ${this.max} characters.`, trigger: "blur"});
        }

        return {input};
    }

    action(): void {
        const form = this.$refs["elForm"] as typeof ElForm;

        form.validate((isValid: boolean) => {
            if (!isValid) return;

            this.$emit("action", this.formData.input);

            this.close();
        });
    }

    close(): void {
        this.isVisible = false;
    }

    open(): void {
        this.isVisible = true;
    }
}
</script>

<style lang="scss" scoped>
@import "src/scss/variables";
.input-count {
    color: $grey-lighten-1;
}

.modal {
    background-color: rgba(0, 0, 0, .5);
}
</style>