<template>
    <div class="job-button position-relative" @click.stop="onClick()">
        <el-button-group class="controls font-large-2x"
                         :id="`controls-${uuid}`"
                         element-loading-spinner="el-icon-loading"
                         v-loading="requestInProgress">
            <el-button class="control control-confirm"
                       @click.stop="onPerformJob()">
                <i class="fa fa-check control-icon" />
            </el-button>
            <el-button class="control control-delete" @click.stop="openCancelDialog()">
                <i class="fa fa-ban control-icon" />
            </el-button>
        </el-button-group>
        <div class="p-1 job-button-main" :class="status">
            <div class="w-100 d-flex justify-content-between">
                <div class="text-primary fw-bold" v-if="mode === 'unit'">{{ job.unit_id ?? job.building_id }}</div>
                <div class="text-primary fw-bold" v-else>{{ capitalize(job.type) }}</div>
                <div class="me-1 text-end text-info fw-bold font-small-1">{{ job.contract_id }}</div>
            </div>
            <div class="d-flex">
                <div class="w-40 d-flex flex-column">
                    <div><b>{{ formatDate(job.perform_date) }}</b></div>
                    <div class="status-description">
                        <div v-if="isPerformed">
                            <b>Performed</b>
                            by <b>{{ job.performed_by }}</b>
                            on <b>{{ formatDate(job.performed_at) }}</b>
                        </div>
                        <div v-else-if="isCancelled">
                            <b>Cancelled</b>
                            by <b>{{ job.cancelled_by }}</b>
                            on <b>{{ formatDate(job.cancelled_at) }}</b>
                        </div>
                    </div>
                </div>
                <div class="pl-3 w-60 d-flex flex-column justify-content-center align-items-end overflow-hidden">
                    <div class="text-end">{{ job.description }}</div>
                    <div class="text-end fst-italic font-small-2 cancelled-because" v-if="!!job.cancelled_because">
                        <i class="fa fa-ban me-1" />
                        <span>{{ job.cancelled_because }}</span>
                    </div>
                </div>
            </div>
        </div>
        <text-area-dialog
            title="Reason"
            buttonActionText="Cancel job"
            ref="textAreaDialog"
            :min="3"
            :max="250"
            @action="onCancelJob($event)" />
    </div>
</template>

<script lang="ts">
import Vue from "@/extensions/Vue";
import {Options, prop} from "vue-class-component";
import JobData from "@/http/data/job-data";
import anime from "animejs";
import {jobContext} from "@/store";
import TextAreaDialog from "@/components/ui/TextAreaDialog.vue";

class Props {
    job = prop({
        required: true,
        type: Object
    });
    mode = prop({
        required: false,
        type: String,
        default: "type"
    });
}

@Options({
    name: "JobButton",
    components: {
        TextAreaDialog: TextAreaDialog
    }
})
export default class JobButton extends Vue.with(Props) {
    declare job: JobData;
    declare mode: string;

    cancelJob = jobContext.actions.cancelJob;
    performJob = jobContext.actions.performJob;

    showControls = false;
    requestInProgress = false;

    textAreaDialog!: TextAreaDialog;

    mounted(): void {
        this.$events.on("hide-job-controls", (uuid: number|undefined) => {
            if (uuid && uuid === this.uuid) return;

            this.hideControls();
        });

        this.textAreaDialog = this.$refs["textAreaDialog"] as TextAreaDialog;
    }

    get isCancelled(): boolean {
        return !!this.job.cancelled_at;
    }

    get isPerformed(): boolean {
        return !this.isCancelled && !!this.job.performed_at;
    }

    get status(): string {
        if (this.isCancelled) return "is-cancelled";
        if (this.isPerformed) return "is-performed";
        else return "is-unfinished";
    }

    displayControls(): void {
        if (this.showControls) return;

        this.showControls = true;

        anime({
            targets: `#controls-${this.uuid}`,
            translateX: ["100%", 0],
            duration: 500,
            easing: "easeInOutSine"
        });
    }
    
    hideControls(): void {
        if (!this.showControls) return;

        this.showControls = false;

        anime({
            targets: `#controls-${this.uuid}`,
            translateX: [0, "101%"],
            duration: 500,
            easing: "easeInOutSine"
        });
    }

    async onCancelJob(reason: string): Promise<void> {
        this.requestInProgress = true;

        await this.cancelJob({
            uuid: this.job.uuid,
            reason
        });

        this.requestInProgress = false;

        this.hideControls();
    }

    onClick(): void {
        if (this.isPerformed || this.isCancelled) return;

        if (!this.showControls) {
            this.$events.emit("hide-job-controls", this.uuid);
            
            this.displayControls();
        } else {
            this.hideControls();
        }
    }

    async onPerformJob(): Promise<void> {
        this.requestInProgress = true;

        await this.performJob(this.job.uuid);

        this.requestInProgress = false;

        this.hideControls();
    }

    openCancelDialog(): void {
        this.textAreaDialog.open();
    }
}
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

.cancelled-because {
    color: $grey-darken-1;
}

.control {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    &:hover {
        filter: brightness(.8);
    }

    &-confirm {
        background-color: $primary;
    }

    &-delete {
        background-color: $secondary;
    }

    &-icon {
        color: $light;
        font-size: 2em;
    }
}

.controls {
    display: flex;
    position: absolute;
    right: 0;
    height: 100%;
    width: 40%;
    min-width: 200px;
    transform: translateX(101%);
}

.job-button {
    border-top: $secondary 2px solid;
    overflow: hidden;

    &:hover {
        cursor: pointer;
    }

    &:last-child {
        border-bottom: $secondary 2px solid;
    }
}

.job-button-main {
    &.is-cancelled {
        border-left: 5px solid $danger;
    }
    &.is-performed {
        border-left: 5px solid $success
    }
    &.is-unfinished {
        border-left: 5px solid transparent;
    }
}

.status-description {
    font-size: .7em;
}

.status-icon {
    font-size: 1.5em;
}
</style>