
import {Options, prop} from "vue-class-component";
import Vue from "../../extensions/Vue";
import {ElForm} from "element-plus";

class Props {
    buttonActionText = prop({
        required: false,
        type: String,
        default: "Ok"
    });
    buttonCloseText = prop({
        required: false,
        type: String,
        default: "Close"
    });
    title = prop({
        required: true,
        type: String
    });
    min = prop({
        required: false,
        type: Number,
        default: 0
    });
    max = prop({
        required: false,
        type: Number,
        default: -1
    });
}

@Options({
    name: "TextAreaDialog"
})
export default class TextAreaDialog extends Vue.with(Props) {
    declare buttonActionText: string;
    declare buttonCloseText: string;
    declare title: string;
    declare min: number;
    declare max: number;

    isVisible = false;

    formData = {
        input: ""
    }

    get inputCount(): number {
        return this.formData.input.length;
    }

    get formRules(): Record<string, unknown> {
        const input = [];

        if (this.min > 0) {
            input.push(
                {type: "string", required: true, message: "Field cannot be empty."},
                {min: this.min, message: `Input must contain at least ${this.min} characters.`, trigger: "blur"},
            );
        } else {
            input.push(
                {type: "string", required: false, message: "Input must be of type [string]."}
            );
        }

        if (this.max > 0) {
            input.push({max: this.max, message: `Input cannot contain more than ${this.max} characters.`, trigger: "blur"});
        }

        return {input};
    }

    action(): void {
        const form = this.$refs["elForm"] as typeof ElForm;

        form.validate((isValid: boolean) => {
            if (!isValid) return;

            this.$emit("action", this.formData.input);

            this.close();
        });
    }

    close(): void {
        this.isVisible = false;
    }

    open(): void {
        this.isVisible = true;
    }
}
